import React, { useState } from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Formik } from 'formik';
import * as Yup from 'yup';
import Footer from '../ui-components/footer';
import { postAPI } from '../common/api';
import { useNavigate } from 'react-router-dom';
import Header from '../ui-components/header';

const theme = createTheme();

const PasswordSchema = Yup.object().shape({
    email: Yup.string().email('Invalid email').required('Required'),
});

export default function SignIn() {

    const navigate = useNavigate();
    const [error, setError] = useState("")


    const submitValues = (values, { setSubmitting }) => {


        postAPI('/user/forgot-password', values).then((data) => {
            setSubmitting()
            if (data.code === 200)
                navigate('/login');
            else {
                setError(data.message)
            }


        })
    };

    return (
        <ThemeProvider theme={theme}>
            <Header />
            <Container component="main" maxWidth="xs"  className="container">
                <CssBaseline />
                <Box
                    sx={{
                        marginTop: 8,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                        <LockOutlinedIcon />
                    </Avatar>
                    <Typography component="h1" variant="h5">
                        Forgot Password
                    </Typography>
                    <Formik
                        initialValues={{ email: '' }}
                        validationSchema={PasswordSchema}
                        onSubmit={submitValues}

                    >
                        {({ isSubmitting, touched, errors, values, handleChange, handleSubmit }) => (
                            <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1 }}>
                                <TextField
                                    margin="normal"
                                    helperText={touched.email ? errors.email : ""}
                                    error={touched.email && Boolean(errors.email)}
                                    value={values.email}
                                    onChange={handleChange} fullWidth
                                    id="email"
                                    label="Email Address"
                                    name="email"
                                    autoComplete="email"
                                    autoFocus
                                />

                                {error && <p className='error'>{error}</p>}
                                <Button
                                    type="submit"
                                    fullWidth
                                    variant="contained"
                                    sx={{ mt: 3, mb: 2 }}
                                    disabled={isSubmitting}
                                >
                                    Submit
                                </Button>

                            </Box>)}
                    </Formik>
                </Box>
            </Container>
            <Footer />
        </ThemeProvider>
    );
}