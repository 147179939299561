import React from "react";
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Footer from '../ui-components/footer'
import Header from '../ui-components/header'
import { useNavigate } from 'react-router-dom';


const theme = createTheme();

export default function Home() {
  let navigate = useNavigate();
  function handleClick() {
    navigate('/login');
  };
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Header login={true}/>
      <main>
        {/* Hero unit */}
        <Box
          sx={{
            bgcolor: 'background.paper',
            pt: 8,
            pb: 6,
          }}
        >
          <Container maxWidth="sm" className="container">
            <Typography
              component="h1"
              variant="h4"
              align="center"
              color="text.primary"
              gutterBottom
            >
              Build your presence
            </Typography>
            <Typography variant="h6" align="center" color="text.secondary" paragraph className="homeText">
            <b>Numinous | njuːmɪnəs </b><br/>
            having a strong religious or spiritual quality;<br/>
            indicating or suggesting the presence of a divinity. <br/>
            </Typography>
            <Stack
              sx={{ pt: 4 }}
              direction="row"
              spacing={2}
              justifyContent="center"
            >
              <Button variant="contained" onClick={handleClick}>Try It</Button>
              {/* <Button variant="outlined">Secondary action</Button> */}
            </Stack>
          </Container>
        </Box>

      </main>
      <Footer />
    </ThemeProvider>
  );
}