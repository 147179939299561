import React from "react";
import { Navigate } from "react-router-dom";
import * as PATHS from './CONSTANTS'

function PrivateRoute({ children }) {
    // const auth = useAuth();
    const isAuthenticated =  localStorage.getItem("isAuthenticated") === 'true';
    return isAuthenticated ? children : <Navigate to={PATHS.LOGIN} />;
  }
  

export default PrivateRoute;