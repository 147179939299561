import { Routes, Route } from 'react-router-dom';
import * as PATHS from './CONSTANTS'
import ProtectedRoute from './ProtectedRoute';
import SignUp from '../pages/signup'
import Login from '../pages/login'
import Home from '../pages/home'
import ForgotPassword from '../pages/forgotPassword'
import Dashboard from '../pages/dashboard'

const RouterConfig = () => {
    return (
        <Routes>
            <Route path='/' exact element={<Home />} />
            <Route path={PATHS.SIGNUP} exact element={<SignUp />} />
            <Route path={PATHS.LOGIN} exact element={<Login />} />
            <Route path={PATHS.FORGOT_PASSWORD} element={<ForgotPassword />} />
            <Route
                path={PATHS.DASHBOARD}
                element={
                    <ProtectedRoute>
                        <Dashboard />
                    </ProtectedRoute>
                }
            />
        </Routes>
    )
}

export default RouterConfig