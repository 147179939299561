import React, { useEffect, useState } from "react";
// import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Footer from '../ui-components/footer'
import Header from '../ui-components/header'
// import { useNavigate } from 'react-router-dom';
import { getAPI } from "../common/api";
import Avatar from '@mui/material/Avatar';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import TwitterIcon from '@mui/icons-material/Twitter';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
const theme = createTheme();

export default function Dashboard() {
    // let navigate = useNavigate();
    const userId = localStorage.getItem('user_id')
    const [data, setData] = useState({})

    useEffect(() => {
        getAPI(`/user/user/${userId}`).then((data) => {
            setData(data)
        })

    }, [userId])

    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <Header />
            <main>
                <Box
                    sx={{
                        bgcolor: 'background.paper',
                        pt: 8,
                        pb: 6,
                    }}
                >
                    <Container maxWidth="sm" className="container">

                        <Avatar alt={data.first_name} src={data.image_url} sx={{ width: 112, height: 112, margin: '30px auto' }} />
                        <Typography variant="h5" align="center" paragraph className="homeText">
                            <h2 className="name">{data.first_name} {data.last_name}</h2><br />
                            having a strong religious or spiritual quality; indicating or suggesting the presence of a divinity.  <br />
                        </Typography>
                        <Stack direction="row" spacing={2} justifyContent="center"
                            alignItems="center">
                            <FacebookIcon />
                            <InstagramIcon />
                            <LinkedInIcon />
                            <TwitterIcon />
                        </Stack>
                        <Stack
                            sx={{ pt: 4 }}
                            direction="row"
                            spacing={2}
                            justifyContent="center"
                        >
                        </Stack>
                        <Typography>I'm passionate about innovation, and have created Distilled to make it easier for people to find each other online. Feel free to connect if you have interesting things to share!</Typography>
                    </Container>
                </Box>

            </main>
            <Footer />
        </ThemeProvider>
    );
}