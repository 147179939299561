import React, { useState } from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { postAPI } from '../common/api';
import { Formik } from 'formik';
import * as Yup from 'yup';
import Footer from '../ui-components/footer';
import { useNavigate } from 'react-router-dom';
import Header from '../ui-components/header';

const SignupSchema = Yup.object().shape({
    firstName: Yup.string()
        .min(2, 'Too Short!')
        .max(50, 'Too Long!')
        .required('Required'),
    lastName: Yup.string()
        .min(2, 'Too Short!')
        .max(50, 'Too Long!')
        .required('Required'),
    userName: Yup.string()
        .min(2, 'Too Short!')
        .max(50, 'Too Long!')
        .required('Required'),
    password: Yup.string()
        .matches(
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%&*s])[A-Za-z\d!@#$%&*s]{6,20}$/,
            "Invalid Password")
        .required('Required'),
    email: Yup.string().email('Invalid email').required('Required'),
});




const theme = createTheme();

export default function SignUp() {
    let navigate = useNavigate();

    const [error, setError] = useState('')

    const submitValues = (values, { setSubmitting }) => {



        let body = {
            "user_name": values.userName,
            "first_name": values.firstName,
            "last_name": values.lastName,
            "email": values.email,
            "password": values.password

        }

        postAPI('/user/register', body).then(data => {
            setSubmitting()
            if (data.code === 200)
                navigate('/login');
            else {
                setError(data.message)
            }

        })
    };



    return (
        <ThemeProvider theme={theme}>
            <Header />
            <Container component="main" maxWidth="xs"  className="container">
                <CssBaseline />
                <Box
                    sx={{
                        marginTop: 8,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                        <LockOutlinedIcon />
                    </Avatar>
                    <Typography component="h1" variant="h5">
                        Sign up
                    </Typography>
                    <Formik
                        initialValues={{ email: '', password: '', firstName: "", lastName: "", userName: "" }}
                        validationSchema={SignupSchema}
                        onSubmit={submitValues}

                    >
                        {({ isSubmitting, touched, errors, values, handleChange, handleSubmit }) => (
                            <Box component="form" onSubmit={handleSubmit} sx={{ mt: 3 }}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm={6}>
                                        <TextField
                                            autoComplete="given-name"
                                            name="firstName"
                                            fullWidth
                                            id="firstName"
                                            label="First Name"
                                            helperText={touched.firstName ? errors.firstName : ""}
                                            error={touched.firstName && Boolean(errors.firstName)}
                                            value={values.firstName}
                                            onChange={handleChange}
                                            autoFocus
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <TextField
                                            // required
                                            fullWidth
                                            id="lastName"
                                            label="Last Name"
                                            name="lastName"
                                            helperText={touched.lastName ? errors.lastName : ""}
                                            error={touched.lastName && Boolean(errors.lastName)}
                                            value={values.lastName}
                                            onChange={handleChange}
                                            autoComplete="family-name"
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            helperText={touched.userName ? errors.userName : ""}
                                            error={touched.userName && Boolean(errors.userName)}
                                            value={values.userName}
                                            onChange={handleChange} fullWidth
                                            id="userName"
                                            label="User Name"
                                            name="userName"
                                            autoComplete="username"
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            helperText={touched.email ? errors.email : ""}
                                            error={touched.email && Boolean(errors.email)}
                                            value={values.email}
                                            onChange={handleChange} fullWidth
                                            id="email"
                                            label="Email Address"
                                            name="email"
                                            autoComplete="email"
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            helperText={touched.password ? errors.password : ""}
                                            error={touched.password && Boolean(errors.password)}
                                            value={values.password}
                                            onChange={handleChange} fullWidth
                                            id="password"
                                            label="Password"
                                            name="password"
                                            type="password"
                                            autoComplete="password"
                                        />
                                    </Grid>
                                </Grid>
                                {error && <p className='error'>{error}</p>}
                                <Button
                                    type="submit"
                                    fullWidth
                                    variant="contained"
                                    sx={{ mt: 3, mb: 2 }}
                                    disabled={isSubmitting}
                                >
                                    Sign Up
                                </Button>
                                <Grid container justifyContent="flex-end">
                                    <Grid item>
                                        <Link href="/login" variant="body2">
                                            Already have an account? Sign in
                                        </Link>
                                    </Grid>
                                </Grid>
                            </Box>)}
                    </Formik>
                </Box>
            </Container>
            <Footer />
        </ThemeProvider>
    );
}