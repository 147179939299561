import * as React from 'react';
// import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
// import Link from '@mui/material/Link';

export default 

function Copyright() {
    return (
        <Typography variant="body2" color="text.secondary" align="center">
            {'© '}
            {/* <Link color="inherit" href="https://mui.com/">
                Your Website
            </Link>{' '} */}
            {new Date().getFullYear()} numinous.me
            {/* {'.'} */}
        </Typography>
    );
}