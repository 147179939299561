

const REACT_APP_BASE = process.env.REACT_APP_BASE;

export async function getAPI(url) {

  let response = {}
  try {
    response = await fetch(REACT_APP_BASE + url,
      {
        headers: {
          'Authorization': localStorage.getItem('token')
        }
      }
    );
    return response.json();
  }

  catch (e) {
    return {}
  }
}

export async function postAPI(url, data) {


  try {
    const response = await fetch(REACT_APP_BASE + url,{
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(data)
  } );

    if (response.status === 401) {
      // clearStorage();
      return;
    }

    return response.json();
  }

  catch (e) {
    return {}
  }
}


