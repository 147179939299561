import React, { useState } from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Formik } from 'formik';
import * as Yup from 'yup';
import Footer from '../ui-components/footer';
import { postAPI } from '../common/api';
import { useNavigate } from 'react-router-dom';
import Header from '../ui-components/header';

const theme = createTheme();

const SigninSchema = Yup.object().shape({
    password: Yup.string()
        .required('Required'),
    email: Yup.string().email('Invalid email').required('Required'),
});

export default function SignIn() {

    const navigate = useNavigate();
    const [error, setError] = useState("")


    const submitValues = (values, { setSubmitting }) => {


        postAPI('/user/login', values).then((data) => {
            setSubmitting()
            if (data.code === 200) {
                localStorage.setItem('token', data.access_token)
                localStorage.setItem('user_id', data.user_id)
                localStorage.setItem('email', data.email)
                localStorage.setItem('isAuthenticated', true)
                navigate('/dashboard');
            }
            else {
                setError(data.message)
            }


        })
    };

    return (
        <ThemeProvider theme={theme}>
            <Header />
            <Container component="main" maxWidth="xs" className="container">
                <CssBaseline />
                <Box
                    sx={{
                        marginTop: 8,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                        <LockOutlinedIcon />
                    </Avatar>
                    <Typography component="h1" variant="h5">
                        Sign in
                    </Typography>
                    <Formik
                        initialValues={{ email: '', password: '' }}
                        validationSchema={SigninSchema}
                        onSubmit={submitValues}

                    >
                        {({ isSubmitting, touched, errors, values, handleChange, handleSubmit }) => (
                            <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1 }}>
                                <TextField
                                    margin="normal"
                                    helperText={touched.email ? errors.email : ""}
                                    error={touched.email && Boolean(errors.email)}
                                    value={values.email}
                                    onChange={handleChange} fullWidth
                                    id="email"
                                    label="Email Address"
                                    name="email"
                                    autoComplete="email"
                                    autoFocus
                                />
                                <TextField
                                    margin="normal"
                                    helperText={touched.password ? errors.password : ""}
                                    error={touched.password && Boolean(errors.password)}
                                    value={values.password}
                                    onChange={handleChange} fullWidth
                                    name="password"
                                    label="Password"
                                    type="password"
                                    id="password"
                                    autoComplete="current-password"
                                />
                                {/* <FormControlLabel
              control={<Checkbox value="remember" color="primary" />}
              label="Remember me"
            /> */}
                                {error && <p className='error'>{error}</p>}
                                <Button
                                    type="submit"
                                    fullWidth
                                    variant="contained"
                                    sx={{ mt: 3, mb: 2 }}
                                    disabled={isSubmitting}
                                >
                                    Sign In
                                </Button>
                                <Grid container>
                                    <Grid item xs>
                                        <Link href="/forgot-password" variant="body2">
                                            Forgot password?
                                        </Link>
                                    </Grid>
                                    <Grid item>
                                        <Link href="/signup" variant="body2">
                                            {"Don't have an account? Sign Up"}
                                        </Link>
                                    </Grid>
                                </Grid>
                            </Box>)}
                    </Formik>
                </Box>
            </Container>
            <Footer />
        </ThemeProvider>
    );
}